
import {
  Component, Inject, Prop, Vue,
} from 'nuxt-property-decorator'
import { SmartSearch } from 'smartsearch.js'
import { RichTextElement } from 'fsxa-api'
import {
  getSmartSearchInstance,
  searchByFacets, TSearchResults,
  TSmartSearchFacetMapping,
  TSmartSearchMapping,
  TSmartSearchParams,
} from '../../../shared/general/services/SmartSearchService'
import { TTeaserSearchResult } from '../../../shared/general/interfaces/TTeaserSearchResult'
import { globalLabel, globalLabelAsString } from '../../../shared/general/services/StoreService'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { formatJoinFromToDate } from '../../../shared/general/services/DateTimeService'
import BaseIcon from '../../base/BaseIcon.vue'
import IDropdownOption from '../../../shared/general/interfaces/IDropdownOption'
import IFilterElement from '../../../shared/general/interfaces/IFilterElement'
import TeaserResults from '../TeaserResults.vue'
import IPill from '../../../shared/general/interfaces/IPill'
import getTeaserImage, { ISmartSearchMediaHost } from '../../../shared/smartsearch/ExtractTeaserImage'
import { TSort } from '../../../shared/general/types/TTeaserSortBy'

type IFacetResult = {
  tags : string[]
}

@Component({
  name: 'TeaserOverview',
  components: {
    BaseIcon,
    TeaserResults,
    Filters: () => import('../../Filters.vue'),
    BasePill: () => import('../../base/BasePill.vue'),
    GlobalLabelWrapper: () => import('../../GlobalLabelWrapper.vue'),
  },
})
export default class TeaserOverview extends Vue {
  @Prop({ default: 12 }) numberOfTeasersToLoad! : number

  @Prop({ default: true }) showTotalNumberOfResults! : boolean

  @Prop({ default: () => [] }) params! : [string, string][]

  @Prop({ required: true }) sort! : TSort

  @Prop({ default: false }) showTagFilter! : boolean

  @Prop({ default: false }) showDateFilter! : boolean

  @Prop({ default: () => [] }) prefilteredTags! : string[]

  @Prop({ default: 'no_search_results_found_label' }) noResultsGlobalLabel! : string

  @Prop({ default: () => [] }) initialFilterQueries! : [string, string]

  @Inject() smartSearchMediaHost! : ISmartSearchMediaHost

  private pageNumber : number = 1

  private totalNumberOfResults : number = 0

  private isLoading : boolean = true

  private showLoadingAnimation : boolean = false

  private searchResults : TTeaserSearchResult[] = []

  private facets : IFacetResult = { tags: [] }

  private selectedFilters : IFilterElement[] = []

  private selectedPills : IPill[] = []

  private topicsOptions : IDropdownOption[] = []

  private timePeriodOptions : IDropdownOption[] = []

  private smartSearchService! : () => Promise<SmartSearch>

  created () {
    const { SMART_SEARCH_HOST: host, PREPARED_SEARCH_TEASER_OVERVIEW: preparedSearch } = this.$config
    this.smartSearchService = getSmartSearchInstance({ host, preparedSearch })
  }

  async mounted () {
    await this.search()

    // This only works because the values in the SmartSearch are labels which are shown in the filter
    this.topicsOptions = this.facets.tags
      .filter((tag) => (this.prefilteredTags.length ? this.prefilteredTags.includes(tag) : true))
      .map((tag) => ({ id: tag, label: tag, value: tag }))
      .sort(this.sortAscending)
    this.timePeriodOptions = this.dateFilterOptions
  }

  private get noResultsLabel () : string | RichTextElement[] {
    return globalLabel(this.noResultsGlobalLabel)
  }

  private sortAscending (a : { label : string }, b : { label : string }) : number {
    return a.label > b.label ? 1 : -1
  }

  private get resultsLabel () : string {
    return globalLabelAsString('results_label')
  }

  private get clearAllLabel () : string | RichTextElement[] {
    return globalLabel('clear_all_label')
  }

  private linkLabel (type ?: string) : string {
    const t = type || ''
    let label = ''
    if (t.includes('article')) label = 'specific_article_link_label'
    else if (t.includes('press')) label = 'specific_press_link_label'
    else if (t.includes('event')) label = 'specific_event_link_label'
    if (!label) return ''
    return globalLabelAsString(label)
  }

  private get teasers () : ITeaserData[] {
    return this.searchResults.map((searchResult : TTeaserSearchResult) : ITeaserData => ({
      headline: searchResult.headline,
      text: searchResult.text,
      link: { url: searchResult.link, label: this.linkLabel(searchResult.templateUid), iconName: 'chevron-right' },
      image: getTeaserImage(
        this.smartSearchMediaHost,
        searchResult.image,
        searchResult.templateUid,
        searchResult.imageAlt,
      ),
      location: searchResult.location,
      date: formatJoinFromToDate({ from: searchResult.publishFrom, to: searchResult.publishTo }),
      mediaType: searchResult.image ? 'image' : 'text',
      aspectRatio: '16:9',
      forceRatio: true,
      teaserVariant: 'vertical',
      tags: searchResult.tags?.replaceAll('|', ' / '),
    }))
  }

  private get thisMonthLabel () : string {
    return globalLabelAsString('this_month_label')
  }

  private get lastMonthLabel () : string {
    return globalLabelAsString('last_month_label')
  }

  private get dateFilterOptions () : IDropdownOption[] {
    const toDropdownOption = (val : string) : IDropdownOption => ({ id: val, label: val, value: val })
    const options : IDropdownOption[] = []

    if (this.thisMonthLabel.length && this.lastMonthLabel.length) {
      options.push(toDropdownOption(this.thisMonthLabel))
      options.push(toDropdownOption(this.lastMonthLabel))
    }
    const countYears : number[] = [0, 1, 2, 3, 4]
    countYears.map((i) => `${new Date().getFullYear() - i}`).forEach((val) => options.push(toDropdownOption(val)))
    return options
  }

  private get searchMapping () : TSmartSearchMapping<TTeaserSearchResult> {
    return {
      link: { key: '' }, // key value is not important
      headline: { key: 'FS_headline' },
      text: { key: 'FS_text' },
      image: { key: 'FS_image' },
      imageAlt: { key: 'FS_image_alt_text' },
      tags: { key: 'FS_tags' },
      publishFrom: { key: 'FS_publish_from_date_range' },
      publishTo: { key: 'FS_publish_to_date_range' },
      location: { key: 'FS_event_location' },
      templateUid: { key: 'FS_template_uid_facet_string' },
    }
  }

  private get facetMapping () : TSmartSearchFacetMapping<IFacetResult> {
    return {
      tags: 'FS_tags',
    }
  }

  private get searchParams () : TSmartSearchParams {
    return {
      searchTerm: '*',
      locale: this.$store.state.Locale.locale,
      pageNumber: this.pageNumber,
      pageSize: this.numberOfTeasersToLoad,
      customParams: [
        ['facet.filter.FS_project_uuid', this.$config.FSXA_PROJECT_ID], // always include!
        ['facet.filter.FS_project_uuid', this.$config.FSXA_REMOTES?.media?.id || ''],
        ['facet.filter.FS_show_in_this_language', 'true'],
        ['facet.filter.FS_subscribed_projects', this.$config.FSXA_PROJECT_ID],
        ...this.params,
        ...this.filterByTags(),
        this.filterByDate(),
        this.sort,
      ],
    }
  }

  private filterByTags () : [string, string][] {
    const selectedTags = this.selectedFilters
      .filter((tab) => tab.listTitle === this.topicsLabel)
      .flatMap((tab) => (tab.options || []))

    const tags = selectedTags.length ? selectedTags.map((opt) => opt.value || '') : this.prefilteredTags

    return tags.map((tag) => (['facet.filter.FS_tags', tag || ''] as [string, string]))
  }

  private filterByDate () : [string, string] {
    const options : string[] = this.selectedFilters.filter((tab) => tab.listTitle === this.timePeriodLabel)
      .flatMap((tab) => (tab.options || []))
      .map((opt) => opt.value || '')
      .filter((value) => !!value)

    let fqPublishDate = ''
    if (options.length === 0 && this.initialFilterQueries.length) {
      fqPublishDate = this.initialFilterQueries.join(':')
    }

    if (options.length > 0) {
      fqPublishDate = options.map((opt) => {
        const [dateFrom, dateTo] = this.toDateFilter(opt)
        const yearFrom = dateFrom.getFullYear()
        const monthFrom = `${dateFrom.getMonth() + 1}`.padStart(2, '0')

        const yearTo = dateTo.getFullYear()
        const monthTo = `${dateTo.getMonth() + 1}`.padStart(2, '0')

        return `FS_publish_from_date_range:[${yearFrom}-${monthFrom} TO ${yearTo}-${monthTo}]`
      }).join(' OR ')
    }

    return ['fq', `${fqPublishDate}`]
  }

  private toDateFilter (period : string) : [Date, Date] {
    const currentDate = new Date()
    const currentDayOfMonth = currentDate.getDate()
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()

    const dateFrom = new Date(currentYear, 0, 1, 0, 0, 0) // first day of the year
    // note: date(day): 0 means the last day of the previews month that is why we increment the month by 1
    const dateTo = new Date(currentYear, 12, 0, 23, 59, 59) // last day of the year

    if (period === this.thisMonthLabel) {
      dateFrom.setMonth(currentMonth)
      // don't fetch teasers in the future even if that would mean: 1st of december only fetches teasers for 1 day!
      dateTo.setMonth(currentMonth, currentDayOfMonth)
    } else if (period === this.lastMonthLabel) {
      const lastMonth = currentMonth - 1
      if (lastMonth < 0) {
        // December
        const lastYear = currentYear - 1
        dateFrom.setFullYear(lastYear, 11, 1)
        dateTo.setFullYear(lastYear, 12, 0) // last day of the month
      } else {
        dateFrom.setMonth(currentMonth - 1)
        dateTo.setMonth(currentMonth, 0) // last day of previews month
      }
    } else {
      const year = parseInt(period || '', 10)
      dateFrom.setFullYear(year)
      if (year === currentYear) {
        dateTo.setFullYear(year, currentMonth, currentDayOfMonth) // prevent loading future teasers
      } else {
        dateTo.setFullYear(year)
      }
    }
    return [dateFrom, dateTo]
  }

  private async doSearch () : Promise<TSearchResults<TTeaserSearchResult, IFacetResult>> {
    this.showLoadingAnimation = true

    const results = await searchByFacets(await this.smartSearchService(), this.searchMapping, this.facetMapping, this.searchParams)

    this.showLoadingAnimation = false
    this.totalNumberOfResults = results.totalNumberOfResults
    this.facets = results.facets
    return results
  }

  private async search () : Promise<void> {
    this.pageNumber = 1
    this.isLoading = true
    const results = await this.doSearch()
    this.isLoading = false
    this.searchResults = results.searchResults
  }

  private async loadMoreSearchResults () : Promise<void> {
    this.pageNumber += 1
    const results = await this.doSearch()
    this.searchResults.push(...results.searchResults)
  }

  private get topicsLabel () : string {
    return globalLabelAsString('topics_label')
  }

  private get timePeriodLabel () : string {
    return globalLabelAsString('time_period_label')
  }

  private get filterOptions () : IFilterElement[] {
    // when one of these labels is missing the "Show results" button does not work. This fixes it
    if (!this.topicsLabel.length || !this.timePeriodLabel.length) return []
    return [
      this.showTagFilter
        ? { listTitle: this.topicsLabel, options: this.topicsOptions, multiSelect: true }
        : { listTitle: '', multiSelect: true },
      this.showDateFilter
        ? { listTitle: this.timePeriodLabel, options: this.timePeriodOptions, multiSelect: true }
        : { listTitle: '', multiSelect: true },
    ].filter((el) => el.listTitle)
  }

  private selectPills () : void {
    this.selectedPills = this.selectedFilters
      .flatMap((tab) => (tab.options || []))
      .map((opt) => ({ label: opt.label || '', value: opt.value || '', checked: true }))
  }

  private setFiltersAndSelectPills (event : { filters : IFilterElement[]}) : void {
    this.selectedFilters = event.filters
    this.selectPills()
  }

  private showResults (filters : IFilterElement[]) : void {
    this.setFiltersAndSelectPills({ filters })
    this.search()
  }

  private clearAllButtonClicked () : void {
    for (let i = 0; i < this.selectedFilters.length; i += 1) {
      this.selectedFilters[i].options = []
    }
    this.selectPills()
    this.search()
  }

  private resetFilter (pill : IPill) : void {
    // find correct list in which the value of the pill is found
    let listIndex = -1
    this.selectedFilters.forEach((filterElement : IFilterElement, index : number) => {
      filterElement.options?.forEach((option) => {
        if (option.value === pill.value) listIndex = index
      })
    })

    if (listIndex > -1) {
      this.selectedFilters[listIndex].options = this.selectedFilters[listIndex].options
        ?.filter((option) => option.value !== pill.value)
      this.selectPills()
    }
    this.search()
  }
}
